<template>
  <span class="upload-excel">
    <input ref="excel-upload-input" class="excel-upload-input" type="file" accept=".xlsx, .xls" @change="handleClick" />
    <span class="drop" @drop="handleDrop" @dragover="handleDragover" @dragenter="handleDragover">
      <el-button :loading="cancelLoading ? false : loading" :type="type" :size="size" @click="handleUpload">
        {{ text }}
      </el-button>
      <el-button type="text" size="small" v-if="showImportCount && excelData.results" style="color: #666">
        (导入 {{ excelData.results.length }} 条)
      </el-button>
      <el-button type="text" size="small" v-if="showFileName && excelData.filename" style="color: #666">
        {{ excelData.filename }}
      </el-button>
    </span>
  </span>
</template>

<script>
import XLSX from 'xlsx';

export default {
  name: 'upload-excel',
  props: {
    beforeUpload: Function, // eslint-disable-line
    onSuccess: Function, // eslint-disable-line
    onSuccessTem: Function, // eslint-disable-line
    text: {
      type: String,
      default: '选择上传文件'
    },
    size: {
      type: String,
      default: 'small'
    },
    type: {
      type: String,
      default: 'default'
    },
    showImportCount: {
      type: Boolean,
      default: false
    },
    showFileName: {
      type: Boolean,
      default: false
    },
    cancelLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      excelData: {
        header: null,
        results: null
      }
    };
  },

  methods: {
    generateData({
      header,
      results,
      filename
    }) {
      this.excelData.header = header;
      this.excelData.results = results;
      this.excelData.filename = filename
      this.onSuccess && this.onSuccess(this.excelData);
    },
    generateDataTem({
      header,
      card_name,
      shop_name,
      results,
      filename
    }) {
      this.excelData.header = header;
      this.excelData.card_name = card_name
      this.excelData.shop_name = shop_name
      this.excelData.results = results;
      this.excelData.filename = filename
      this.onSuccessTem && this.onSuccessTem(this.excelData);
    },
    handleDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      if (this.loading) return;
      const files = e.dataTransfer.files;

      if (files.length !== 1) {
        this.$message.error('Only support uploading one file!');
        return;
      }
      const rawFile = files[0]; // only use files[0]

      if (!this.isExcel(rawFile)) {
        this.$message.error(
          'Only supports upload .xlsx, .xls, .csv suffix files'
        );
        return false;
      }
      this.upload(rawFile);
      e.stopPropagation();
      e.preventDefault();
    },
    handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
    },
    handleUpload() {
      this.$refs['excel-upload-input'].click();
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      if (!rawFile) return;
      this.upload(rawFile);
      this.$emit('upFileName', rawFile.name)
    },
    upload(rawFile) {
      this.$refs['excel-upload-input'].value = null; // fix can't select the same excel

      if (!this.beforeUpload) {
        this.readerData(rawFile);
        return;
      }
      const before = this.beforeUpload(rawFile);
      if (before) {
        this.readerData(rawFile);
      }
    },
    getData(rawFile) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = e => {
          const data = e.target.result;
          const fixedData = this.fixData(data);
          const workbook = XLSX.read(btoa(fixedData), { type: 'base64' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const header = this.getHeaderRow(worksheet) || [];
          const results = XLSX.utils.sheet_to_json(worksheet) || [];
          resolve({ header, results })
        }
        reader.readAsArrayBuffer(rawFile);
      })
    },
    readerData(rawFile) {
      console.log(this.onSuccess, this.onSuccessTem)
      this.loading = true;
      const filename = rawFile.name
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = e => {
          const data = e.target.result;
          const fixedData = this.fixData(data);
          const workbook = XLSX.read(btoa(fixedData), { type: 'base64' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const header = this.getHeaderRow(worksheet);
          const results = XLSX.utils.sheet_to_json(worksheet);
          if (this.onSuccess) {
            this.loading = false;
            this.generateData({
              header,
              results,
              filename
            });
          } else {
            try {
              let headerArr = []
              let resultsArr = []
              for (const key in results[3]) {
                if (results[3].hasOwnProperty(key)) {
                  const element = results[3][key];
                  headerArr.push(element)
                }
              }
              results.forEach((element, index) => {
                if (index > 3) {
                  let obj = {}
                  headerArr.forEach((item, index) => {
                    if (index === 0) {
                      obj[item] = element['商城名称']
                    } else if (index === 1) {
                      obj[item] = element['__EMPTY']
                    } else {
                      obj[item] = element[`__EMPTY_${index - 1}`]
                      console.log(`__EMPTY_${index - 1}`, element)
                    }
                  })
                  resultsArr.push(obj)
                }
              });
              this.generateDataTem({
                header: [results[3]['商城名称'], results[3]['__EMPTY']],
                card_name: results[2]['商城名称'],
                shop_name: results[0]['商城名称'],
                results: resultsArr,
                filename
              })
              this.loading = false;
              resolve();
            } catch (error) {
              this.$message.error('导入的表格和系统模板样式不一致，请核验后重新上传导入')
              this.loading = false;
            }
          }


        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    fixData(data) {
      let o = '';
      let l = 0;
      const w = 10240;
      for (; l < data.byteLength / w; ++l) {
        o += String.fromCharCode.apply(
          null,
          new Uint8Array(data.slice(l * w, l * w + w))
        );
      }
      o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
      return o;
    },
    getHeaderRow(sheet) {
      const headers = [];
      const range = XLSX.utils.decode_range(sheet['!ref']);
      let C;
      const R = range.s.r;
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) {
        /* walk every column in the range */
        const cell = sheet[XLSX.utils.encode_cell({
          c: C,
          r: R
        })];
        /* find the cell in the first row */
        let hdr = 'UNKNOWN ' + C; // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
        headers.push(hdr);
      }
      return headers;
    },
    isExcel(file) {
      return /\.(xlsx|xls|csv)$/.test(file.name);
    }
  }
};
</script>

<style lang="scss" scoped>
.excel-upload-input {
  display: none;
  z-index: -9999;
}

.drop {
  margin-right: 10px;
}
</style>
